import React from 'react';
import darkImage from '../images/dark-mode.png';
import lightImage from '../images/light-mode.png';

const Menu = (props) => {
    return (
        <div className='optionMenu' style={{ backgroundColor: '#be8de0', width: '125px', padding: '15px' }}>
            <span style={{ display: 'flex', justifyContent: 'space-between', textAlign: 'right', fontSize: '11px', lineHeight: '15px' }} onClick={ () => { props.toggleDarkMode() } }>
                DARK MODE {props.darkMode ? "ON" : "OFF" }<img alt="darkmode" src={props.darkMode ? darkImage : lightImage} style={{ maxWidth: '15px' }} />
            </span>
        </div>
    );
}

export default Menu;