import React from 'react';
import '../styles/Header.css';
import '../styles/DarkTheme.css';
import menuImage from '../images/menu.png';
import Menu from './Menu';
//import { darkImage } from '../images/dark-mode.png';

class Header extends React.Component {
    state = {
        menu: false,
        isMobile: false,
        darkMode: JSON.parse( localStorage.getItem('darkMode') )
    }

    componentDidMount = () => {
        this.determineIfMobile();
        window.addEventListener('resize', event => {
            this.determineIfMobile();
        });
    }

    toggleDarkMode = () => {
        this.setDarkMode(!this.state.darkMode);
        localStorage.setItem('darkMode', JSON.stringify( !this.state.darkMode ) );
        this.setState((state, props) => ( ( { darkMode: !state.darkMode } ) ) );
    }

    setDarkMode = () => {
        if (this.state.darkMode === true) {
            document.getElementById("root").classList.add("DarkTheme");
        } else {
            document.getElementById("root").classList.remove("DarkTheme");
        }
    }

    checkIfMobile = () => {
        const root = document.getElementsByClassName("sd-root-modern");
        if (root.length > 0) {
            if (this.state.isMobile) {
                if (!root[0].classList.contains("sd-root-modern--mobile")) {
                    root[0].classList.add("sd-root-modern--mobile");
                }
            } else {
                if (root[0].classList.contains("sd-root-modern--mobile")) {
                    root[0].classList.remove("sd-root-modern--mobile");
                }
            }
        }
    }
    
    determineIfMobile = () => {
        const vWidth = window.visualViewport.width;
        if (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
            navigator.userAgent
            ) || vWidth < 1200) {
            this.setState((state, props) => ( { isMobile: true } ));
        } else {
            this.setState((state, props) => ( { isMobile: false } ));
        }
        this.checkIfMobile();
    }

    toggleMenu = () => {
        this.setState( (state, props) => ( { menu: !state.menu } ));
    }

    render = (props) => {
        this.checkIfMobile();
        this.setDarkMode();
        return (
            <div className="kheader">
                <div style={{ position: 'relative', maxWidth: '1366px', width: '100%', margin: 'auto' }}>
                    <div className="kcontainer">
                        <span>KOKORO</span>                    
                    </div>
                    <div style={{ zIndex: '1000', top: '1.15vh', right: '15px', position: 'absolute', cursor: 'pointer' }}>
                        <img src={menuImage} alt="menu-button" style={{ maxWidth: '30px' }} onClick={ () => { this.toggleMenu(); } } />
                    </div>
                    <div style={{ zIndex: '1000', right: '0px', position: 'absolute' }}>
                        {
                            this.state.menu && (
                                <Menu darkMode={this.state.darkMode} toggleDarkMode={this.toggleDarkMode} />
                            )
                        }
                    </div>
                </div>
            </div>
        );
    }
}



export default Header;