import './App.css';
import KokoroSurvey from './components/Survey';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();
function App() {
  return (
    <div className="App">
        <BrowserRouter history={history}>
          <Routes>
            <Route path="/" element={<KokoroSurvey />} exact={true} />
            <Route path=":guid" element={<KokoroSurvey />} />
          </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
