import React, { useEffect, useState } from 'react';

const Loading = (props) => {
    const [dots, setDots] = useState(3);
    let ellipses = '.';
    for (let dot = 1; dot < dots; dot++) {
        ellipses += '.';
    }

    useEffect(() => {
        const updateEllipses = setInterval(() => {
            if (dots === 3) {
                setDots(1);
            } else {
                setDots(dots + 1);
            }
        }, 1000);
        return () => clearInterval(updateEllipses);
    });

    return (
        <div className="sd-root-modern">
            <div className='survey-message'>Please be patient we are loading your survey now{ellipses}</div>
        </div>
    )
};

export default Loading;