import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { StylesManager, Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import GetSurvey from './Firebase';
import 'survey-core/defaultV2.min.css';
import Header from './Header';
import Error from './Error';
import Loading from './Loading';

StylesManager.applyTheme("defaultV2");

const getSurvey = (guid, setSurvey, setError) => {
    if (guid != null) {
        GetSurvey(guid).then((json) => {
            const survey = new Model(json.surveyData);
            survey.myGuid = guid;
            survey.mySurveyId = json.surveyId;
            survey.myAnswerMap = json.questionMap;
            survey.setError = setError;
            survey.onAfterRenderPage.add(fixEmbeddedHtml);
            survey.onComplete.add(processResult);
            setSurvey(survey);
        }, (reason) => {
            setError(reason.message);
        });
    } else {
        // No Guid in url
        setError("5");
    }
}

const fixEmbeddedHtml = () => {
    const elements = document.querySelectorAll('.sd-question__description .sv-string-viewer');
    if (elements && elements.length > 0) {
        elements.forEach(element => {
            if (element.innerHTML.indexOf('&lt;') > -1) {
                element.innerHTML = element.innerText;
            }
        });
    }

    const tables = document.getElementsByTagName('table');
    if (tables && tables.length > 0) {
        for(const table of tables) {
            const tBody = table.querySelectorAll('tbody')[0];
            const tBodyRows = tBody.querySelectorAll('tr');
            if (tBodyRows.length === 1) {
                var tHeadRow = table.querySelector('thead tr');
                var firstHeaderTd = tHeadRow.querySelectorAll('td')[0];
                var firstTd = tBodyRows[0].querySelectorAll('td')[0];
                firstTd.style.display = 'none';
                firstTd.style.visibility = 'hidden';
                firstHeaderTd.style.visibility = 'hidden';
                firstHeaderTd.style.display = 'none';
            }
        };
    }
}

const blackList = [
    'push',
    'shift',
    'unshift',
    'pop',
    'splice',
    'onArrayChanged'
];
const processResult = (survey) => {
    try {
        const resultData = [];
        for (const key in survey.data) {
            const question = survey.getQuestionByName(key);
            if (question)  {
                const myMap = survey.myAnswerMap[key]; 
                const type = myMap.type;
                if (type === 'control_textbox' || type === 'text') {
                    const answer = Object.entries(myMap.answers);
                    const item = {
                        questionId: myMap.id,
                        answerId: answer[0][0],
                        value: question.value,
                        type
                    }
                    resultData.push(item);
                } else if (type === 'control_yesno') {
                    const item = {
                        questionId: myMap.id,
                        answerId: question.value,
                        value: '',
                        type
                    }
                    resultData.push(item);
                } else if (type === 'checkbox') {
                    const aValue = Array.isArray(question.value) && question.value.length > 0 ? question.value[0] : question.value;
                    const item = {
                        questionId: myMap.id,
                        answerId: myMap.answers[question.value],
                        value: aValue,
                        type
                    }
                    resultData.push(item);
                } else {
                    const qValue = Object.entries(question.value);
                    if (qValue.length === 0) {
                        const answer = Object.entries(myMap.answers);
                        const item = {
                            questionId: myMap.id,
                            answerId: answer.length === 1 ? answer[0][0] : myMap.answers[question.value],
                            value: question.value,
                            type
                        }
                        resultData.push(item);
                    }
                    else {
                        for (const [id, val] of qValue) {
                            if (blackList.indexOf(id) > -1) {
                                continue;
                            }
                            const answer = Object.entries(myMap.answers);
                            let answerId;
                            let lookup = val;
                            let value = val;
                            switch(type) {
                                case 'control_checkbox':
                                    answerId = myMap.answers[answer[0][1]];
                                    break;
                                case 'control_widget':
                                case 'control_radio':
                                    answerId = answer[0][0];
                                    break;
                                case 'control_matrix':
                                    lookup = id;
                                    break;
                            }
                            const item = {
                                questionId: myMap.id,
                                answerId: answer.length === 1 ? answerId : myMap.answers[lookup],
                                value,
                                type
                            }
                            resultData.push(item);
                        }
                    }
                }
            }
        }
        const data = {
            guid: survey.myGuid,
            surveyId: survey.mySurveyId,
            results: resultData
        }
        let localApiEndPoint = "";
        if (window.location.href.indexOf("http://192.168.1.84") > -1) {
            localApiEndPoint = "http://192.168.1.84:3001";
        }
        else if (window.location.href.indexOf("http://localhost") > -1) {
            localApiEndPoint = "http://localhost:3001";
        }
    
        const apiEndPoint = `${localApiEndPoint}/api/saveSurvey`;
        axios.post(apiEndPoint, data).then(res => {
        }, reason => {
            let statusCode = 6;
            if (reason && reason.response && reason.response.data && reason.response.data.statusCode) {
                statusCode = reason.response.data.statusCode;
            }
            survey.setError(`${statusCode}`);
        });
    } catch (error) {
        survey.setError(`${6}`);
    }
    
}

const KokoroSurvey = (props) => {
    const [survey, setSurvey] = useState(null);
    const [error, setError] = useState(null);
    const { guid } = useParams();
    
    if (survey == null && error == null) {
        getSurvey(guid, setSurvey, setError);
    }
    
    return (
        <div>
            <Header />
            {
                (survey === null && !error && (
                    <Loading />
                )) || (error && (
                    <Error errorState={error} />
                )) || (
                    <Survey model={survey} />
                )
            }
        </div>
    )
}

export default KokoroSurvey;